<template>
  <div class="demo-date-picker">
    <div class="block">

      <el-date-picker
        @change="chooseTime"
        v-model="DateTime"
        type="datetimerange"
        :shortcuts="shortcuts"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
        :default-time="defaultTime2"
      />

      <span class="demonstration"
        >comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
        {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}</span
      >
    </div>
  </div>

 

  <div class="cards">
    <el-card class="box-card" v-for="(item, index) in headerList" :key="index">
      <template #header>
        <div class="card-header">
          <div></div>
          <div></div>
        </div>
      </template>
      <div class="price">{{ item.value }}</div>
      <div class="text">{{ item.name }}</div>
    </el-card>
  </div>

  <div id="box">
    <div class="box-header">
      <div>Earbings overview</div>
      <div>
        <!-- <el-button type="primary" plain>Primary</el-button>
                <el-button type="success" plain>Success</el-button> -->
      </div>
    </div>
    <div class="box-date">
      comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
      {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}
    </div>
    <div id="main">
      <!-- 图表 -->
    </div>
  </div>

  <div id="box1">
    <div class="box-header">
      <div>Working time</div>
      <div>
        <!-- <el-button type="primary" plain>Primary</el-button>
                <el-button type="success" plain>Success</el-button> -->
      </div>
    </div>
    <div class="box-date">
      comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
      {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}
    </div>
    <div id="main2">
      <!-- 图表 -->
    </div>
  </div>

  
</template>

<script setup>
import * as echarts from "echarts";
import { size } from "lodash";
import { nextTick, onMounted } from "vue";
import request from "@/utils/request.js";
import { getEchartsData } from "@/api/center.js";
import { ref } from "vue";
import { id } from "element-plus/es/locale";

const headerList = ref([]);
const dataList = ref([]);
const dataList2 = ref([]);
const DateTime = ref("");
const dateArr = ref([]);
const consultantId = ref(null);
const defaultTimeSta = ref(new Date().setMonth(new Date().getMonth() - 1));
const defaultTimeEnd = ref(new Date());

const defaultTime2 = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)];

const shortcuts = [
  {
    text: "Last week",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: "Last month",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: "Last 3 months",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
];
onMounted(() => {
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  consultantId.value = userInfo.id;
  //默认传参时间
  let query = {
    startTimeD: ProcessingTime(new Date().setMonth(new Date().getMonth() - 1)),
    endTimeD: ProcessingTime(new Date()),
    consultantId: consultantId.value,
  };
  //调Echarts数据
  getList(query);
  //默认时间
  defaultTimeSta.value = zhuanHua(defaultTimeSta.value);
  defaultTimeEnd.value = zhuanHua(defaultTimeEnd.value);
});

//选择时间触发事件
const chooseTime = async (e) => {
  let c = e;
  c = c.map((item) => zhuanHua(item));
  dateArr.value = c;

  e = e.map((item) => ProcessingTime(item));
  //转化时间格式
  const query = {
    startTimeD: e[0],
    endTimeD: e[1],
    consultantId: consultantId.value,
  };
  getList(query);
};

//时间转化
const zhuanHua = (dateString) => {
  // const dateString = "Sat May 06 2023 00:00:00 GMT+0800";
  const date = new Date(dateString);

  const monthIndex = date.getMonth();
  const day = date.getDate();

  // 定义英文月份的数组
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // 获取指定索引处的英文月份
  const month = months[monthIndex];
  return `${month} ${day}`;
};

//时间转换函数
const ProcessingTime = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月份从0开始，所以需要加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const paddedHours = hours < 10 && hours >= 0 ? `0${hours}` : hours;
  const paddedMinutes = minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 && seconds >= 0 ? `0${seconds}` : seconds;
  return `${year}-${month}-${day} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

//获取数据
const getList = async (params) => {
  let res = await getEchartsData(params);
  if (res.code == "200") {
    let a = res.data;
    let allPrice = a.sumPerMinutes + a.sumChatGift + a.sumLiveGift + a.sumGift;
    dataList.value = [
      {
        value: a.sumPerMinutes,
        name: "Private Session",
        text: ((a.sumPerMinutes / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumChatGift,
        name: "Private Session Gift",
        text: ((a.sumChatGift / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumLiveGift,
        name: "Live",
        text: ((a.sumLiveGift / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumGift,
        name: "Gift",
        text: ((a.sumGift / allPrice) * 100).toFixed(1) + "%",
      },
    ];
    dataList.value.map((item) => {
      if (item.text == "NaN%" || item.text == "Infinity%" || item.text == "-Infinity%") {
        return (item.text = "0%");
      }
      return item;
    });

    let allPrice2 = a.sumTimeLiveSeconds + a.sumTimeChatSeconds;
    dataList2.value = [
      {
        value: a.sumTimeLiveSeconds,
        values: a.sumTimeLive,
        name: "Free Live",
        text: ((a.sumTimeLiveSeconds / allPrice2) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumTimeChatSeconds,
        values: a.sumTimeChat,
        name: "Private Session",
        text: ((a.sumTimeChatSeconds / allPrice2) * 100).toFixed(1) + "%",
      },
    ];
    headerList.value = [
      { name: "Total Earnings", value: "$" + a.TotalRevenue },
      { name: "Avg/Hour", value: "$" + a.svnRevenue },
      { name: "Private Session", value: a.sumTimeChat },
      { name: "Live", value: a.sumTimeLive },
      { name: "New Members", value: a.sumFollower },
    ];

    dataList2.value.map((item) => {
      if (item.text == "NaN%" || item.text == "Infinity%" || item.text == "-Infinity%") {
        return (item.text = "0%");
      }
      return item;
    });

    initEcharts(dataList.value);
    initEcharts2(dataList2.value);
  }
};

//初始化图表
const initEcharts = (data) => {
  console.log("进入图表一",data)
  let chartDom = document.getElementById("main");
  let myChart = echarts.init(chartDom);
  let option;
  option = {
    color: ["#9252f3", "#fbcb3e", "#f05486", "red"],
    grid: {
      containLabel: true,
      left: "10%", // 设置图表容器左边距
      top: "10%", // 设置图表容器上边距
      right: "10%", // 设置图表容器右边距
      bottom: "10%", // 设置图表容器下边距
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "15%",
      left: "50%",
      orient: "vertical", // 将图例垂直排列
      itemGap: 80,
      right: "50%",
      formatter: (name) => {
        if (data.length > 0) {
          const item = data.filter((item) => item.name == name)[0];
          return (
            "{title|" +
            item.name +
            "}{value|" +
            "$" +
            item.value +
            "}{text|" +
            item.text +
            "}"
          );
        }
      },
      textStyle: {
        rich: {
          title: {
            color: "#333333",
            fontSize: 20,
            padding: [3, 20, 3, 10],
            width: 100,
            fontSize: 14,
          },
          value: {
            fontSize: 22,
            lineHeight: 20,
            width: 80,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
          text: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
        },
      },
    },
    grid: {
      left: "10%", // 距离左边界的距离
      right: "10%", // 距离右边界的距离    1
    },
    series: [
      {
        name: "Earbings overview",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        center: ["30%", "50%"],
        label: {
          show: false,
          position: "center",
          formatter: function (params) {
            return params.name + ": " + "$" + params.value;
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };

  option && myChart.setOption(option);
};

//初始化图表
const initEcharts2 = (data) => {
  console.log("进入图表二",data)
  let chartDom = document.getElementById("main2");
  let myChart = echarts.init(chartDom);
  let option;

  option = {
    color: ["#f05486", "#9252f3"],
    grid: {
      containLabel: true,
      left: "10%", // 设置图表容器左边距
      top: "10%", // 设置图表容器上边距
      right: "10%", // 设置图表容器右边距
      bottom: "10%", // 设置图表容器下边距
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "36%",
      left: "50%",
      orient: "vertical", // 将图例垂直排列
      itemGap: 80,
      right: "50%",
      formatter: (name) => {
        if (data.length > 0) {
          const item = data.filter((item) => item.name == name)[0];
          return (
            "{title|" + item.name + "}{value|" + item.values + "}{text|" + item.text + "}"
          );
        }
      },
      textStyle: {
        rich: {
          title: {
            color: "#333333",
            fontSize: 20,
            padding: [3, 20, 3, 10],
            width: 100,
            fontSize: 14,
          },
          value: {
            fontSize: 22,
            lineHeight: 20,
            width: 80,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
          text: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
        },
      },
    },

    grid: {
      left: "10%", // 距离左边界的距离
      right: "10%", // 距离右边界的距离
    },
    series: [
      {
        name: "Working time",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        center: ["30%", "50%"],
        label: {
          show: false,
          position: "center",
          formatter: function (params) {
            return params.name + ": " + params.data.values;
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };

  option && myChart.setOption(option);
};

</script>

<style lang="scss" scoped>

  .demo-date-picker {
    display: flex;
    width: 50%;
    padding: 0;
    flex-wrap: wrap;
  }

  .demo-date-picker .block {
    display: flex;
    padding: 30px 0;
    text-align: center;
    border-right: solid 1px var(--el-border-color);
    margin-left: 10px;
    color: #8d76a0;
    // flex: 1;
  }

  .demo-date-picker .block:last-child {
    border-right: none;
  }

  .demo-date-picker .demonstration {
    display: block;
    color: #8d76a0;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .demonstration {
    width: 300px;
    margin-top: 5px;
  }

  .cards {
    width: 100%;
    height: 180px;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;

    /*水平滚动条*/

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }

    .box-card {
      width: 180px;
      height: 150px;
      background-color: #f4f1f8;
      margin-bottom: 20px;
    }

    .price {
      color: purple;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  #box1,
  #box {
    width: 98%;
    height: 600px;
    background: #f4f1f8;
    border-radius: 6px;
    margin: auto;
    overflow: hidden;

    .box-date {
      padding: 20px;
      color: #1a8f75;
    }

    .box-header {
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      color: #9b6f80;
      font-size: 25px;
      font-weight: 600;
    }

    #main {
      width: 100%;
      height: 500px;
    }

    #main2 {
      width: 100%;
      height: 500px;
    }
  }



</style>
